import React from 'react';

const CampaignCategorySelect = ({ category, setCategory, onChange }) => {
    const handleChange = (e) => {
      const newCategory = e.target.value;
      setCategory(newCategory);
      onChange(newCategory); // 선택한 카테고리 값을 전달합니다.
    };
  
    return (
      <select className="select-box" value={category} onChange={handleChange}>
      <option value="">카테고리 선택</option>
      <option value="18871">가을노트북SALE</option>
      <option value="18872">기간한정(로켓직구)</option>
      <option value="83">와우회원70%할인</option>
      {/* 추가 카테고리 */}
      </select>
    );
  };

export default CampaignCategorySelect;
