import React, { useState, useEffect } from 'react';
import './BestProduct.css';
import { fetchBestProducts } from './apibest';
import { getShortenUrl } from './components/coupangapi';
import Footer from './components/Footer';
import { Link } from 'react-router-dom';
import PlusSign from './PlusSign';
import Manual from './Manual';
import Promotion from './Promotion';
import MyPicked from './components/MyPicked';
import ProductUpdates from './ProductUpdates'; // ProductUpdates 컴포넌트를 임포트합니다.

const subCategoryMapping = [
  { id: '497135', name: '노트북' },
  { id: '497136', name: '데스크탑' },
  { id: '510541', name: '모니터' }, // 497141
  { id: '497244', name: '휴대폰' },
  { id: '497246', name: '태블릿' },
  { id: '497252', name: '스마트워치' },
  { id: '497142', name: '키보드마우스' },
  { id: '497181', name: '프린터' },
  { id: '178401', name: '이어폰' },
  { id: '497256', name: '전동킥보드/자전거' },
  { id: '403245', name: '냉장고' },
  { id: '178456', name: 'TV' },
  { id: '486733', name: '세탁기' },
  { id: '413352', name: '청소기' },
  { id: '333478', name: '뷰티/헤어가전' },
  { id: '178713', name: '건강가전' },
  { id: '445736', name: '주방가전' },
  // Add more mappings as needed
];

const orderedSubCategories = [
  '노트북',
  '태블릿',
  '휴대폰',
  '스마트워치',
  '데스크탑',
  '모니터',
  '프린터',
  '키보드마우스',
  '이어폰',
  'TV',
  '냉장고',
  '세탁기',
  '청소기',
  '전동킥보드/자전거',
  '뷰티/헤어가전',
  '건강가전',
  '주방가전'
  // Add more sub-categories in your desired order
];

const Product = ({ product_name, imageUrl, link, discountRate, basePrice, priceValue }) => {

  const handleProductClick = async () => {
    try {
      const shortenUrl = await getShortenUrl(link);
      window.open(shortenUrl, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.error('Failed to open link:', error);
    }
  }

  return (
    <div className="product" onClick={handleProductClick}>
      <img src={`https:${imageUrl}`} alt={product_name} />
      <h4>{product_name}</h4>
      <p>기준가 : <del>{basePrice}</del>원({discountRate}↓)</p>
      <p>판매가 : {priceValue}원</p>
    </div>
  );
};

const ProductCategory = ({ title, products, loading }) => {
  const [showArrow, setShowArrow] = useState(false);

  const handleScroll = () => {
    setShowArrow(false);
  };

  useEffect(() => {
    const productContainer = document.querySelector('.product-list1');
    if (productContainer) {
      productContainer.addEventListener('scroll', handleScroll);
      return () => productContainer.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    if (!loading) {  // 로딩이 끝나면 화살표를 표시
      setShowArrow(true);
    }
  }, [loading]);

  // 첫 번째 상품의 category와 sub_category를 가져옵니다.
  const category = products.length > 0 ? products[0].category : null;
  const subCategory = products.length > 0 ? products[0].sub_category : null;

  // Find the sub-category object based on the title
  const subCategoryInfo = subCategoryMapping.find((info) => info.name === title);

  return (
    <div className="product-category">
      <div className="category-header">
        <Link to={'/new'} state={{ icategory: category, isubCategory: subCategory }}><h3 className="link"> {title}</h3></Link>
        {showArrow && <div className="scroll-arrow">→</div>}
      </div>
      {loading ? (
        <p>상품을 불러오는 중...<span className="loading-icon"></span></p>
      ) : (
        <div className="product-list1">
          {products.slice(0, 5).map(product => (
            <Product key={product.item_id} {...product} />
          ))}

          {
            products.length >= 5 && (

              <PlusSign icategory={category} isubCategory={subCategory} />
            )}
        </div>
      )}
    </div>
  );
};
const BestProduct = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryOrder, setCategoryOrder] = useState([]);
  const [showManual, setShowManual] = useState(false); // 모달 상태 관리
  const [isManualVisible, setIsManualVisible] = useState(false);

  const [isPromotionOpen, setIsPromotionOpen] = useState(false);
  const [isMyPickedOpen, setIsMyPickedOpen] = useState(false);

  useEffect(() => {
    // 페이지 로드 시 모달 상태 확인
    const hidePromotion = localStorage.getItem('hidePromotion');
    
    // 로컬 스토리지에 'true'가 저장되어 있지 않으면 모달을 보여줌
    if (hidePromotion !== 'true') {
      setIsPromotionOpen(true);
    }
  }, []);


  const openPromotion = () => {
    setIsPromotionOpen(true);
  };

  const closePromotion = () => {
    setIsPromotionOpen(false);
  };

  const openMyPicked= () => {
    setIsMyPickedOpen(true);
  };

  const closeMyPicked = () => {
    setIsMyPickedOpen(false);
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const productData = await fetchBestProducts('new');
        setProducts(productData);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, []);

  useEffect(() => {
    // Sort the categories based on the orderedSubCategories array
    const sortedCategories = orderedSubCategories.map(categoryName => {
      const categoryInfo = subCategoryMapping.find(info => info.name === categoryName);
      return categoryInfo ? categoryInfo.id : null;
    });

    setCategoryOrder(sortedCategories.filter(Boolean));
  }, []);

  const groupedProducts = products.reduce((acc, product) => {
    if (!acc[product.sub_category]) {
      acc[product.sub_category] = [];
    }
    acc[product.sub_category].push(product);
    return acc;
  }, {});

  return (
    <div className="container">
      <header>
        <img src="/assets/crawlmall.png" alt="Crawl Mall Logo" className="crawlmall-logo" />
        &nbsp;&nbsp;&nbsp;
        <span className="guide-link" onClick={() => setIsManualVisible(true)}>
          이용가이드 📖
        </span>
        &nbsp;&nbsp;&nbsp;
        <span className="guide-link" onClick={openPromotion}>
          프로모션
        </span>
        {isPromotionOpen && <Promotion onClose={closePromotion} />}
        &nbsp;&nbsp;&nbsp;
        <span className="guide-link" onClick={openMyPicked}>
          MyPick🥟
        </span>
        {isMyPickedOpen && <MyPicked onClose={closeMyPicked} />}

      </header>
      {isManualVisible && <Manual onClose={() => setIsManualVisible(false)} />}
      <main>
        <h2 className="category-list">쿠팡 전자기기 최대 할인 특가 상품(신상품)</h2>
        {loading ? (
          <p>상품을 불러오는 중...<span className="loading-icon"></span></p>
        ) : (
          categoryOrder.map(subCategoryId => (
            <ProductCategory
              key={subCategoryId}
              title={subCategoryMapping.find(info => info.id === subCategoryId)?.name}
              products={groupedProducts[subCategoryId] || []}
              loading={loading}
            />
          ))
        )}
      </main>
      <Footer />
      <ProductUpdates /> {/* ProductUpdates 컴포넌트를 BestProduct 컴포넌트 내에 추가합니다. */}
    </div>
  );
}



export default BestProduct;
