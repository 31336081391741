import React, { useState, useEffect } from 'react';
import './Footer.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            if (scrollY > 0) {
                setIsVisible(false);
            } else {
                setIsVisible(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`footer ${isVisible ? '' : 'hidden'}`}>
            <div className="footer">
                © {currentYear} 크롤에이아이. All Rights Reserved.
            </div>
        </div>
    );
};

export default Footer;