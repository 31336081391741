import React, { useState, useEffect } from 'react';
import './MyPicked.css';
import { getShortenUrl } from './coupangapi';  // 필요한 함수를 import합니다.
import Alarm from './Alarm'; // Alarm 컴포넌트 import

const MyPicked = ({ onClose }) => {
    const [pickedItems, setPickedItems] = useState([]);

    const [alarmStatus, setAlarmStatus] = useState('off'); // 알람의 ON/OFF 상태를 관리

    // 컴포넌트가 마운트될 때 localStorage의 알람 상태를 가져와서 상태를 업데이트합니다.
    useEffect(() => {
        const status = Alarm.getAlarmStatus();
        setAlarmStatus(status);
    }, []);  // 빈 dependency array를 넣어 컴포넌트가 마운트될 때만 실행되게 합니다.

    const handleAlarmToggle = () => {
        const newStatus = alarmStatus === 'on' ? 'off' : 'on';
        setAlarmStatus(newStatus);
        Alarm.setAlarmStatus(newStatus);
    };

    useEffect(() => {
        const itemIds = pickedItems.map(item => item.original.item_id);
        if (itemIds && itemIds.length > 0) {
            Alarm.sendAlarm(itemIds, alarmStatus);
        }
    }, [alarmStatus, pickedItems]);

    useEffect(() => {
        const savedItems = JSON.parse(localStorage.getItem('pickedItems') || '{"original": [], "updated": []}');

        const combinedItems = savedItems.original.map(originalItem => {
            const updatedInfos = savedItems.updated
                .filter(uItem => uItem.item_id === originalItem.item_id)
                .sort((a, b) => b.timestamp - a.timestamp)  // 수정된 부분
            // .slice(0, 5);  // 가장 최근의 5개를 가져옴
            //console.log("Updated info : " + JSON.stringify(updatedInfos));
            return { original: originalItem, updated: updatedInfos };
        });

        setPickedItems(combinedItems);
    }, []);


    const handleRemovePickedItems = () => {
        const itemsToRemove = pickedItems.filter(item => !item.original.isChecked);

        // 수정된 부분: 필터링된 상품들을 원하는 JSON 구조에 맞게 저장합니다.
        const newSavedItems = {
            original: itemsToRemove.map(item => item.original),
            updated: itemsToRemove.flatMap(item => item.updated)
        };

        localStorage.setItem('pickedItems', JSON.stringify(newSavedItems));
        setPickedItems(itemsToRemove);
    };


    const handleProductClick = async (link) => {   // 새로 추가된 함수입니다.
        try {
            const shortenUrl = await getShortenUrl(link);
            window.open(shortenUrl, '_blank', 'noopener,noreferrer');
        } catch (error) {
            console.error('Failed to open link:', error);
            // 필요에 따라 사용자에게 오류 메시지를 표시할 수 있습니다.
        }
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day}`;
        //return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    return (
        <div className="mypicked-modal-overlay" onClick={onClose}>
            <div className="mypicked-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="mypicked-close-button" onClick={onClose}>X</button>
                <button onClick={handleRemovePickedItems}>선택 항목 삭제</button>
                <div className="mypicked-notice">
                    찜(Pick)한 상품 가격 변동, 재입고 등 상태 변경 시 <br />기기 알림 서비스<br />
                </div>
                <div className="mypicked-alarm-section">
                    <span>기기 알림 신청</span>
                    <label className="switch">
                        <input
                            type="checkbox"
                            checked={alarmStatus === 'on'}
                            onChange={handleAlarmToggle}
                        />
                        <span className="slider"></span>
                    </label>

                </div>
                <ul className="mypicked-product-list">
                    {pickedItems.map(item => (
                        <li key={item.original.item_id} className="mypicked-product-item">
                            <div className="mypicked-product-checkbox">
                                <input
                                    type="checkbox"
                                    id={`checkbox-${item.original.item_id}`}
                                    checked={item.original.isChecked}
                                    onChange={(e) => {
                                        item.original.isChecked = !item.original.isChecked;
                                        setPickedItems([...pickedItems]);
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                />
                                <label htmlFor={`checkbox-${item.original.item_id}`}></label>
                            </div>
                            <div className="mypicked-product-detail-container">
                                <div className="mypicked-product-detail" onClick={() => handleProductClick(item.original.link)}>
                                    <img src={item.original.imageUrl} alt={item.original.product_name} className="mypicked-product-image" />
                                    <div className="mypicked-product-info">
                                        <span>{item.original.product_name}</span>
                                        <div className="mypicked-info-item">
                                            <span className="mypicked-info-label">기준가:</span><del>{item.original.basePrice}원</del>({item.original.discountRate}↓)
                                        </div>
                                        <div className="mypicked-info-item">
                                            <span className="mypicked-info-label">할인가:</span>{item.original.priceValue}원
                                        </div>
                                    </div>
                                </div>
                                {item.updated.map(updatedItem => (
                                    <div className="mypicked-updated" onClick={() => handleProductClick(updatedItem.link)} key={updatedItem.timestamp}>
                                        <div className="mypicked-product-info">
                                            <div className="mypicked-info-item">
                                                {formatDate(updatedItem.timestamp)}, {updatedItem.discountRate}↓, {updatedItem.priceValue}원, {updatedItem.outofstock || "재고있음"}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );




};

export default MyPicked;