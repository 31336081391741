import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // <-- 추가
import ProductDetail from './components/ProductDetail';
import './ProductPriceHistory.css'

// http://localhost:3000/PriceHistory/18505724744

const ProductPriceHistory = () => {
  const { item_id } = useParams(); // <-- 여기서 item_id 값을 가져옵니다.
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://crawlmall.shop:8080/product_history/${item_id}`);
        const data = await response.json();
        console.log(data);

        setProductData(data);
      } catch (error) {
        console.error("Failed to fetch product data:", error);
      }
    };

    fetchData();
  }, [item_id]);

  return (
    <div className="product-price-history-container">
      <div className="product-price-history-content">
        {productData ? <ProductDetail product={productData} /> : <div>Loading...</div>}
      </div>
    </div>
);
  
};

export default ProductPriceHistory;
