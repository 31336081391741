import React, { useState, useEffect, useRef } from 'react';
import socketIOClient from "socket.io-client";

const SERVER_URL = "https://crawlmall.shop:3001";

function ProductUpdates() {
    const [updates, setUpdates] = useState([]);
    const socketRef = useRef(null);
    const [hasProductUpdates, setHasProductUpdates] = useState(false);
    const [buttonClose, setButtonClose] = useState(false);
    const [socketError, setSocketError] = useState(null); // 소켓 연결 오류를 저장하기 위한 상태

    useEffect(() => {
        socketRef.current = socketIOClient(SERVER_URL);

        let storedPickedItems = JSON.parse(localStorage.getItem('pickedItems') || "{}");

        // Check the format of storedPickedItems
        if (!storedPickedItems.original || !storedPickedItems.updated) {
            localStorage.removeItem('pickedItems');
            storedPickedItems = {}; // Reset to an empty object for safety
        }

        const savedProducts = storedPickedItems.original || [];

         // 소켓 연결 오류 처리
         socketRef.current.on('connect_error', (error) => {
            setSocketError(error.message || "Socket connection failed");
            console.error("Socket connection failed:", error);
        });

        socketRef.current.on('updatesAvailable', (data) => {
            const currentStoredPickedItems = JSON.parse(localStorage.getItem('pickedItems') || "{}");
            const currentSavedProducts = currentStoredPickedItems.original || [];

            const updatedProducts = currentSavedProducts.flatMap(product => {
                const updates = data.filter(updateItem => updateItem.item_id === product.item_id);

                if (updates.length === 0) {
                    return {
                        original: product,
                        updated: null
                    };
                }

                return updates.map(update => ({
                    original: product,
                    updated: update
                }));
            });

            const currentUpdatedItems = currentStoredPickedItems.updated || [];
            const newUpdates = updatedProducts.filter(item => {
                return item.updated && !currentUpdatedItems.some(existingItem => existingItem.id === item.updated.id);
            }).map(item => item.updated);

            const newPickedItems = {
                original: currentSavedProducts,
                updated: [...currentUpdatedItems, ...newUpdates]
            };

            // updated 정보에 변동사항이 있는지 확인
            const hasUpdates = JSON.stringify(newPickedItems.updated) !== JSON.stringify(currentUpdatedItems);

            // localStorage 업데이트
            localStorage.setItem('pickedItems', JSON.stringify(newPickedItems));

            if (hasUpdates) {
                setHasProductUpdates(true);
                console.log("상품에 변경이 있습니다.");
            }
        });



        socketRef.current.emit('checkUpdates', savedProducts.map(product => product.item_id));
	    // 30초마다 서버에 상품 변경 정보 보내기
        const interval = setInterval(() => {
            const currentStoredPickedItems = JSON.parse(localStorage.getItem('pickedItems') || "{}");
            const currentSavedProducts = currentStoredPickedItems.original || [];

            socketRef.current.emit('checkUpdates', currentSavedProducts.map(product => product.item_id));
            console.log("checkupdates");
        }, 30000); // 30초

        return () => {
            socketRef.current.off('connect_error'); // 오류 핸들러 제거
            socketRef.current.off('updatesAvailable');
            socketRef.current.disconnect();
		   clearInterval(interval);  // Interval을 제거
        };
    }, []);

    if (buttonClose) {
        return null;
    }

    return (
        <>
            {hasProductUpdates && (
                <div style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1002,
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '5px',
                    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)'
                }}>


                    <h2>Pick 상품에 변경이 있습니다.</h2>
                    <button
                        onClick={() => setButtonClose(true)}
                        style={{ padding: '10px 20px', marginTop: '10px', cursor: 'pointer', borderRadius: '3px', border: 'none', backgroundColor: '#007BFF', color: 'white' }}
                    >
                        확인
                    </button>

                </div>
            )}
        </>
    );

}

export default ProductUpdates;
