// apiproduct.js

const BASE_URL = "https://crawlmall.shop:8080/product/best";

export const fetchBestProducts = async (condition) => {
  let url;
  
    url = `${BASE_URL}/${condition}`;
  

  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch product data for ${condition} and ${category}`);
  }
  const data = await response.json();
  return data.data;
};
