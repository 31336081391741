import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceLine, Label } from 'recharts';
import { useNavigate } from 'react-router-dom'; // 1. useNavigate를 import 합니다.
import { getShortenUrl } from './coupangapi';  // 위에서 정의한 함수를 가져옵니다.
import './ProductDetail.css';

const ProductDetail = ({ product }) => {
    const navigate = useNavigate();  // 2. useNavigate 훅을 사용하여 navigate 함수를 가져옵니다.

    const handleProductClick = async () => {
        try {
            const shortenUrl = await getShortenUrl(product.link);
            window.open(shortenUrl, '_blank', 'noopener,noreferrer');
        } catch (error) {
            console.error('Failed to open link:', error);
            // 필요에 따라 사용자에게 오류 메시지를 표시할 수 있습니다.
        }
    };

    //별 모양을 만들기 위한 로직
    const renderStars = (rating) => {
        let stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(
                <span className="star" key={i}>
                    {i < rating ? "⭐" : "☆"}
                </span>
            );
        }
        return stars;
    };

    const transformedPriceChanges = product.priceChanges.map(change => ({
        date: new Date(change.date).getTime(),
        price: parseInt(change.price.replace(/[^0-9]/g, ""), 10)
    }));

    console.log(transformedPriceChanges);

    const maxPriceData = transformedPriceChanges.reduce((max, p) => p.price > max.price ? p : max, transformedPriceChanges[0]);
    const minPriceData = transformedPriceChanges.reduce((min, p) => p.price < min.price ? p : min, transformedPriceChanges[0]);

    if (transformedPriceChanges.length === 0) {
        return (
            <div className="container">
                <div className="image-container">
                    <img src={product.imageUrl} alt={product.productName} />
                    <h2>{product.productName}</h2>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                    가격 변동 정보가 없습니다.
                </div>
            </div>
        );
    }

    return (
        <div className={`product-detail-item ${product.outofstock ? 'out-of-stock' : ''}`} onClick={handleProductClick}>
        <div className="product-detail-content">
            <div className="product-detail-image-wrapper">
                <img src={product.imageUrl} alt={product.productName} />
                    {product.outofstock && <div className="product-detail-out-of-stock-label">{product.outofstock}</div>}
                </div>
                <h2 className="product-detail-title">{product.productName}</h2>
                <div className="product-detail-details">
                    <div className="product-detail-info">
                        <div className="product-detail-info-item discount">
                            <span className="product-detail-info-label discount">할인율:</span> {product.discountRate}↓
                        </div>
                        <div className="product-detail-info-item out-of-stock">
                            <span className="product-detail-info-label out-of-stock">{product.outofstock}</span>
                        </div>
                        <div className="product-detail-info-item">
                            <span className="product-detail-info-label">기존 가격:</span> <del>{product.basePrice}원</del>
                        </div>
                        {product.priceValue &&
                            <div className="product-detail-info-item">
                                <span className="product-detail-info-label rainbow-text">할인 가격:</span> {product.priceValue}원
                            </div>}
                        {product.salePrice &&
                            <div className="product-detail-info-item">
                                <span className="product-detail-info-label">최종 가격:</span> <strong>{product.salePrice}</strong>
                            </div>}
                        {product.rating &&
                            <div className="product-detail-info-item">
                                <span className="product-detail-info-label">평점:</span> {renderStars(product.rating)}
                            </div>}
                        {product.ratingCount != 0 && product.ratingCount &&
                            <div className="product-detail-info-item">
                                <span className="product-detail-info-label">상품평수:</span> <span className="product-detail-icon">👤</span>({product.ratingCount})
                            </div>}
                        <div className="product-detail-badge">
                            {product.ccidbadge}
                        </div>
                    </div>
                </div>
            </div>
            <div className="product-detail-graph-container">
                <h3>가격 변동 그래프</h3>
                <LineChart
                    width={Math.min(500, window.innerWidth * 0.8)} // 최대 너비를 화면 너비의 80%로 설정
                    height={300}
                    data={transformedPriceChanges}
                    margin={{ top: 20, right: 50, bottom: 20, left: 10 }} // 여기에 마진을 추가했습니다.
                >
                    <Line type="monotone" dataKey="price" stroke="#8884d8" />
                    <ReferenceLine x={maxPriceData.date} stroke="red">
                        <Label
                            value={`최고점: ${maxPriceData.price.toLocaleString()}원`}
                            position="center"
                            offset={10} // 필요에 따라 조절 가능
                            fill="red"
                        />
                    </ReferenceLine>

                    <ReferenceLine
                        x={minPriceData.date}
                        stroke="green"
                        label={{ value: `최저점: ${minPriceData.price.toLocaleString()}원`, position: 'insideBottomRight', offset: 10 }}
                    />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis
                        dataKey="date"
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        tickFormatter={(timestamp) => new Date(timestamp).toLocaleDateString()}
                    />
                    <YAxis domain={[0.8 * minPriceData.price, 1.2 * maxPriceData.price]} />
                    <Tooltip labelFormatter={(timestamp) => new Date(timestamp).toLocaleDateString()} />
                </LineChart>
            </div>
            <button onClick={() => window.location.href = 'https://crawlmall.shop'} style={{ margin: '20px' }}>메인으로</button>

        </div>
    );
};

export default ProductDetail;
