// ProductPriceModal.js
import React from 'react';
import ProductPriceDetail from './ProductPriceDetail';
import './ProductPriceModal.css';

const ProductPriceModal = ({ itemId, onClose }) => {
  const handleClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onClose();
  };

  return (
    <div className="price-modal">
      <div className="price-modal-content">
        <ProductPriceDetail itemId={itemId} />
        <button onClick={handleClose} onTouchStart={handleClose}>닫기</button>
      </div>
    </div>
  );
};

export default ProductPriceModal;
