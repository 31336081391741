import React from 'react';

const CategorySelect = ({ category, setCategory, onChange }) => {
    const handleChange = (e) => {
      const newCategory = e.target.value;
      setCategory(newCategory);
      onChange(newCategory); // 선택한 카테고리 값을 전달합니다.
    };
  
    return (
      <select className="select-box" value={category} onChange={handleChange}>
      <option value="">카테고리 선택</option>
      <option value="178155">디지털</option>
      <option value="178156">가전</option>
      {/* 추가 카테고리 */}
      </select>
    );
  };

export default CategorySelect;
