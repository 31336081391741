import React, {useEffect} from 'react';
import './Manual.css';

const Manual = ({ onClose }) => {
    useEffect(() => {
        // 모달이 마운트되면 body에 modal-active 클래스 추가
        document.body.classList.add('modal-active');
        
        return () => {
          // 모달이 언마운트되면 modal-active 클래스 제거
          document.body.classList.remove('modal-active');
        }
      }, []);
  return (
    <div className="manual-overlay">
      <div className="manual-content">
        <button className="close-button" onClick={onClose}>X</button>
        <h1>이용가이드</h1>
        <section>
          <h2>크롤몰 이용 안내</h2>
          <ul>
            <li> 타 사의 쇼핑몰과 달리 쿠팡은 하루에도 몇 번씩 할인율 변동에 따른 가격 변동성이 매우 큽니다.</li>
            <li> 제품을 구입하고 나서 30% 이상의 할인 가격으로 제품가가 변경된다면 손해 본 느낌일 겁니다.</li>
            <li> 크롤몰에서는 쿠팡의 가격 변동성을 실시간으로 분석하여 최대 할인 상품들을 선별하여 제공합니다.</li>
            <li> 쿠팡 검색으로 찾기 힘든 상품을 할인순, 가격순, 평점순, 상품평수로 빠르게 정렬하여 볼 수 있습니다.</li>
            <li> 인기 할인 상품들이 품절되기 전에 한 발 빠르게 상품을 찾아 구매할 수 있습니다.</li>
            <li> 크롤몰 이용은 무료이며 쿠팡파트너스로 인증 받아 소정의 수수료를 제공받을 수 있습니다.</li>            
            <li> 크롤몰은 보안(https) 인증 사이트로 강화된 보안이 적용되어 있어 안심하고 이용하셔도 됩니다.</li>            

          </ul>
        </section>
        <section>
          <h2>크롤몰 개인정보방침</h2>
          <ul>
            <li> 크롤몰에서는 별도의 고지 없이 개인정보를 수집하지 않습니다.</li>
            
            <li> 상품 카테고리 추가 및 이용 개선 문의는 <a href="mailto:capbang2002@gmail.com">capbang2002@gmail.com</a> 으로 문의해 주세요.</li>
          </ul>
        </section>
        <button className="close-center-button" onClick={onClose}>닫기</button>
      </div>
    </div>
  );
}

export default Manual;
