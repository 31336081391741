import React, { useState, setState } from 'react';
import { Link } from 'react-router-dom';
import './PlusSign.css';

const PlusSign = ({ icategory, isubCategory }) => {
  const [category, setCategory] = useState(icategory);
  const [subCategory, setSubCategory] = useState(isubCategory);

  return (
    <div className="plus-container">
      <Link to={'/new'} state ={{ icategory:category, isubCategory:subCategory }}>
        <div className="plus-sign">+</div>
      </Link>
    </div>
  );
};

export default PlusSign;
