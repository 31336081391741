import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './App';
import AppUsed from './AppUsed';
import AppReturn from './AppReturn';
import AppCampaign from './AppCampaign';
import Best from './BestProduct';
import ProductPriceHistory from './ProductPriceHistory'; // ProductPriceHistory 컴포넌트 임포트

/*    <Route path="/" element={<Home />} />
      <Route path="/newPage" element={<HomeAll />} />
      <Route path="/used" element={<AppUsed />} />
      <Route path="/return" element={<AppReturn />} />
      <Route path="/campaign" element={<AppCampaign />} />
      <Route path="/main" element={<Main />} />
      <Route path="/best" element={<Best />} />
      <Route path="/test" element={<Test />} />
      <Route path="/plus" element={<SignTest />} />
      */

const AppRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Best />} /> 
      <Route path="/new" element={<App />} />
      <Route path="/used" element={<AppUsed />} />
      <Route path="/return" element={<AppReturn />} />
      <Route path="/campaign" element={<AppCampaign />} />
      <Route path="/priceHistory/:item_id" element={<ProductPriceHistory />} /> {/* 추가된 라인 */}
    </Routes>
  </BrowserRouter>
);

export default AppRouter;
