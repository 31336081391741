import axios from "axios";
import { initializeApp } from 'firebase/app'; // 이렇게 수정하세요
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyALlmSfG7ci_YBUFdftJ-7n8LTMg6B3Y2E",
  authDomain: "crawlmall.firebaseapp.com",
  projectId: "crawlmall",
  storageBucket: "crawlmall.appspot.com",
  messagingSenderId: "1070114543589",
  appId: "1:1070114543589:web:4774cba796dbd2088c410c",
  measurementId: "G-FVSWBPC9HK"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


const Alarm = {
    getAlarmStatus: () => {
        return localStorage.getItem('alarm_status') || 'off'; // localStorage에서 알람 상태를 가져옵니다. 값이 없으면 'off'를 반환합니다.
    },

    setAlarmStatus: (status) => {
        localStorage.setItem('alarm_status', status); // 알람 상태를 localStorage에 저장합니다.
    },

    sendAlarm: async (itemIds, status) => {
        try {
            const deviceInfo = window.navigator;
            const deviceIds = deviceInfo.userAgent;
            const os = deviceInfo.platform;
            const model = deviceInfo.product;
            const maker = "Web Browser";
            
            const deviceToken = await getToken(messaging, {vapidKey: "BGl0P3fdcwFeAlmxl55EZOxhTpwhAVQFsNJD6Dh12gJ7BZUUeGPA3e5V4QihnKfB2QH9u5wOllQAQ06_Xiwb1VA"});

            console.log("Sending itemIds:", itemIds); // itemIds 배열 출력
            console.log("deviceIds:", deviceIds); // itemIds 배열 출력
            console.log("deviceToken:", deviceToken); // itemIds 배열 출력
            console.log("os:", os); // itemIds 배열 출력
            console.log("model:", model); // itemIds 배열 출력
            console.log("status:", status); // itemIds 배열 출력

            if (deviceToken) {

                const response = await axios.post("https://crawlmall.shop:8080/insert/alarm", {
                    itemIds: itemIds,
                    status: status,
                    deviceIds: deviceIds,
                    deviceToken: deviceToken,
                    os: os,
                    model: model,
                    maker: maker
                });

                console.log(response.data);

                // 알람 상태 업데이트
                Alarm.setAlarmStatus(status);
            } else {
                // 푸시 권한이 거부된 경우 알람을 보내지 않습니다.
                console.log('Push permission denied.');
            }

        } catch (error) {
            console.error('Failed to send alarm:', error);
        }
    }
};

export default Alarm;
