import React from 'react';

const ProductSelect = ({ product, setProduct, options, onChange }) => {
  return (
    <select
      className="select-box" value={product}
      onChange={(e) => {
        onChange(e); // 이벤트 객체를 전달합니다.
      }}
    >
      <option value="" disabled>상품 선택</option>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

export default ProductSelect;
