import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom'; // <-- 새로운 import 문
import { fetchProductData } from './api'; // api.js에서 fetchProductData를 가져옵니다.
import Product from './components/Product';
import Footer from './components/Footer';
import CategorySelect from './components/CategorySelect';
import ProductSelect from './components/ProductSelect';
import './App.css';

const App = () => {
  const [opacity, setOpacity] = useState(1);
  const [visibility, setVisibility] = useState('visible');
  const [category, setCategory] = useState('');
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState('');
  const [productOptions, setProductOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [displayProducts, setDisplayProducts] = useState([]);
  const [lastIndex, setLastIndex] = useState(20);
  const [sortOption, setSortOption] = useState('할인순');  // 새로운 state 추가
  const [sortedProducts, setSortedProducts] = useState([]);
  const [finalProducts, setFinalProducts] = useState([]);
  const [updateTime, setUpdateTime] = useState('');
  const [showDisclaimer, setShowDisclaimer] = useState(true);  // 새로운 상태 추가
  
  const [marginTop, setMarginTop] = useState('0px');
  const [marginTopList, setMarginTopList] = useState('0px');

    useEffect(() => {
        const headerHeight = document.querySelector('header').offsetHeight;
        setMarginTop(`${headerHeight+100}px`);
        setMarginTopList(`${headerHeight}px`);
    }, []);



  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 && !searchKeyword) {
        const additionalProducts = sortedProducts.slice(lastIndex, lastIndex + 20); // sortedProducts를 사용
        if (additionalProducts.length > 0) {
          setFinalProducts(prevFinalProducts => [...prevFinalProducts, ...additionalProducts]);
          setLastIndex(prevLastIndex => prevLastIndex + 20);
        }
      }

      if (window.scrollY > 0) {
        setOpacity(0);
        setVisibility('hidden');
      } else {
        setOpacity(1);
        setVisibility('visible');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastIndex, sortedProducts, searchKeyword]); // finalProducts를 제거


  const handleCategoryChange = (newCategory) => {
    setOpacity(1);
    setVisibility('visible');
    setShowDisclaimer(false);  // Disclaimer 컴포넌트를 숨깁니다.

    if (newCategory === '178155') {
      setProductOptions([
        { id: '497135', name: '노트북' },
        { id: '497136', name: '데스크탑' },
        { id: '497141', name: '모니터' },
        { id: '497244', name: '휴대폰' },
        { id: '497245', name: '태블릿' },
        { id: '497252', name: '스마트워치' },
        { id: '497142', name: '키보드마우스' },
        { id: '497181', name: '프린터' },
        { id: '178401', name: '이어폰' },
        { id: '497256', name: '전동킥보드/자전거' },
      ]);
    } else if (newCategory === '178156') {
      setProductOptions([
        { id: '403245', name: '냉장고' },
        { id: '178456', name: 'TV' },
        { id: '486733', name: '세탁기' },
        { id: '413352', name: '청소기' },
        { id: '333478', name: '뷰티/헤어가전' },
        { id: '178713', name: '건강가전' },
        { id: '445736', name: '주방가전' },
      ]);
    }
    setProduct('');  // 또는 다른 초기값
  };

  const handleProductChange = async (e) => {
    setShowDisclaimer(false);  // Disclaimer 컴포넌트를 숨깁니다.
    const newProduct = e.target.value;
    setProduct(newProduct);
    setIsLoading(true);  // 로딩 시작

    try {
      const fetchedData = await fetchProductData(newProduct, "return");
      setProducts(fetchedData.data);

      // updateTime 값을 setUpdatetime로 설정
      setUpdateTime(fetchedData.updateTime);
    } catch (error) {
      console.error('Failed to fetch products:', error);
    } finally {
      setIsLoading(false);  // 로딩 종료
    }
  };


  // 새로운 함수를 추가합니다. 클릭 시 메인 페이지로 이동
  const goToMain = () => {
    navigate('/');
  };

  // finalProducts -> filteredProducts 이름 변경
  const filteredProducts = useMemo(() => {
    let filtered = [...sortedProducts];
    if (searchKeyword) {
      filtered = filtered.filter((product) =>
        product && product.product_name
          ? product.product_name.toLowerCase().includes(searchKeyword.toLowerCase())
          : true
      );
    }
    return filtered;
  }, [sortedProducts, searchKeyword]);

  // 이제 filteredProducts가 초기화된 후에 이 useEffect가 실행됩니다.
  useEffect(() => {
    if (filteredProducts.length > 0) {
      setFinalProducts(filteredProducts.slice(0, lastIndex));
    }
  }, [filteredProducts, lastIndex]);

  useEffect(() => {
    // 여기서 sortedPrice라는 새로운 필드를 추가해서 정렬에 사용합니다.
    let sortedArray = [...products].map(product => ({
      ...product,
      sortedPrice: parseInt(product.priceValue.replace(/,/g, ''), 10) || 0
    }));

    switch (sortOption) {
      case '낮은 가격순':
        sortedArray.sort((a, b) => a.sortedPrice - b.sortedPrice);
        break;
      case '높은 가격순':
        sortedArray.sort((a, b) => b.sortedPrice - a.sortedPrice);
        break;
      case '평점순':
        sortedArray.sort((a, b) => b.rating - a.rating);
        break;
      case '상품평수':
        sortedArray.sort((a, b) => Number(b.ratingCount) - Number(a.ratingCount));
        break;
      default:
        // 할인순 등 기타 경우
        break;
    }

    // 정렬이 끝난 후에는 sortedPrice 필드를 제거합니다.
    sortedArray = sortedArray.map(({ sortedPrice, ...rest }) => rest);

    setSortedProducts(sortedArray);

  }, [sortOption, products]);

  useEffect(() => {
    // 이 부분에서 finalProducts를 업데이트 합니다.
    // sortedProducts가 변경되거나 searchKeyword가 변경되면 이 부분이 실행됩니다.
    setFinalProducts(filteredProducts.slice(0, lastIndex));
  }, [filteredProducts, lastIndex]);

  /* local time zone
  const formatDate = (updateTime) => {
    // 문자열을 파싱합니다.
    const match = updateTime.match(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/);

    // 매칭 결과를 구조 분해 할당을 통해 변수로 가져옵니다.
    if (match) {
      const [, year, month, day, hour, minute, second] = match;
      return `${year}년 ${month}월 ${day}일 ${hour}시 ${minute}분`;
    } else {
      // 올바르지 않은 형식의 입력 문자열인 경우 원래 문자열을 반환합니다.
      return dateTimeStr;
    }
  }

  const parseDate = (updateTime) => {
    const match = updateTime.match(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/);

    if (match) {
        const [, year, month, day, hour, minute, second] = match;
        
        // Date 객체를 생성합니다.
        // 주의: 월은 0부터 시작하기 때문에 1을 빼줍니다.
        return new Date(year, month - 1, day, hour, minute, second);
    } else {
        return null;
    }
}

  const isWithinOneHour = (updateTime) => {
    const timeWithoutOffset = parseDate(updateTime);
    const updateTimeDate = new Date(timeWithoutOffset);
    const currentDate = new Date();

    // 현재 시간과의 차이를 계산합니다 (단위는 밀리초)
    const timeDifference = currentDate - updateTimeDate;

    // 밀리초로 1시간은 3600000 밀리초입니다.
    return timeDifference < 3600000;
  }
*/
const formatDate = (updateTime) => {
  const dateObj = new Date(updateTime);

  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); 
  const day = String(dateObj.getDate()).padStart(2, '0'); 
  const hours = String(dateObj.getHours()).padStart(2, '0'); 
  const minutes = String(dateObj.getMinutes()).padStart(2, '0'); 
  const seconds = String(dateObj.getSeconds()).padStart(2, '0'); 

  return `${year}년${month}월${day}일 ${hours}시${minutes}분`;
}

const isWithinOneHour = (updateTime) => {
  const updateTimeDate = new Date(updateTime);
  const currentDate = new Date();

  // 현재 시간과의 차이를 계산합니다 (단위는 밀리초)
  const timeDifference = currentDate - updateTimeDate;

  // 밀리초로 1시간은 3600000 밀리초입니다.
  return timeDifference < 3600000;
}
  const isValidDate = (updateTime) => {
    // 정규 표현식을 사용하여 문자열 형식을 검사합니다.
    const match = updateTime.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.\d{3}\+\d{2}:\d{2}$/);

    // 매칭 결과가 없으면 잘못된 형식입니다.
    if (!match) return false;

    // 생성된 Date 객체의 연, 월, 일을 다시 추출하고 원래 값과 비교합니다.
    return true;
}


  return (
    <div className="App">
      <header className="App-header" style={{ opacity, visibility }}>
        <div className="header-top">
          <span className="to-main" onClick={goToMain}>&lt; 메인</span>
          <h1>반품 특가!</h1>
          {updateTime && isValidDate(updateTime) && isWithinOneHour(updateTime) ?
            <p>상품 갱신 탐지 : {formatDate(updateTime)}</p> : null}
          <CategorySelect
            category={category}
            setCategory={setCategory}
            onChange={handleCategoryChange}
          />
          <ProductSelect
            product={product}
            setProduct={setProduct}
            options={productOptions}
            onChange={handleProductChange}
          />
        </div>
        <div className="header-bottom">
          <input
            type="text"
            placeholder="키워드 검색"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            style={{ width: '200px', height: '20px' }}
          />
          {/* 새로운 콤보박스 추가 */}
          <select onChange={(e) => setSortOption(e.target.value)} value={sortOption} style={{ width: '100px', height: '34px' }}>
            <option value="할인순">할인순</option>
            <option value="낮은 가격순">낮은 가격순</option>
            <option value="높은 가격순">높은 가격순</option>
            <option value="평점순">평점순</option>
            <option value="상품평수">상품평수</option>
          </select>
        </div>
      </header>
      <main>
        {isLoading ? (
          <div className="loading-container">
            <span className="loading-text">상품 정보를 가져오는 중...</span>
            <span className="running-icon">🏃‍♂️</span>
          </div>
        ) : null}
        {/* Disclaimer 조건부 렌더링 */}
        {showDisclaimer && 
        <div class="rounded-box" style={{ marginTop }}>
          <div className="disclaimer">
          <p className="disclaimer-text"> 상품을 선택하세요!</p>
        </div>
        </div>}
        <div className="product-list" style={{ marginTopList }}>
          {finalProducts.map((product) => (
            <Product key={product.id} {...product} />
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default App;