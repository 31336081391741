import React from 'react';
import './Promotion.css';

const Promotion = ({ onClose }) => {
    const handleCloseForever = () => {
        localStorage.setItem('hidePromotion', 'true');  // 로컬 스토리지에 상태 저장
        onClose();
    };
    return (
        <div className="promotion-overlay">
            <div className="promotion-content">
                <button className="close-button" onClick={onClose}>X</button>
                <h2>크롤몰만의 특별한 제안</h2>
                <h4>쿠팡 삼성슈퍼세일 특가 추천 상품(19%)과 <br />크롤몰 할인 특가를 한 눈에 비교해보세요!</h4>
                <p>쿠팡의 저렴한 제품을 크롤몰에서는 <br />키워드와 할인순 정렬로 쉽게 찾을 수 있답니다!</p>
                <img src="/assets/sssupersale1.png" alt="삼성슈퍼세일" className="promo-image" />
                <h1>쿠팡 삼성슈퍼세일 상품!(19% 할인)</h1>
                <img src="/assets/sssupersale_vs20b956axg.png" alt="특가 상품" className="special-product" />

                <h1>크롤몰에선~(<span className="surprise">35% 할인이 있네!!</span>)</h1>
                <img src="/assets/crawlmall_vs20b956axg.png" alt="크롤몰 특가 상품" className="crawl-product" />

                <h2>쿠팡 전자기기 특가는 크롤몰에서 <br />쉽고 빠르게!!</h2>
                <div className="close-button-line">
                    <button className="close-forever-left-button" onClick={handleCloseForever}>
                        다시 안보기 X
                    </button>

                    <button className="close-center-button" onClick={onClose}>닫기</button>
                </div>
            </div>
        </div>
    );
}

export default Promotion;
