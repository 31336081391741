import React, { useState } from 'react';
import './Product.css';
import { getShortenUrl } from './coupangapi';  // 위에서 정의한 함수를 가져옵니다.
import Alarm from './Alarm'; // Alarm 컴포넌트 import
import ProductPriceModal from './ProductPriceModal';  // 모달 컴포넌트를 임포트 합니다.


const Product = ({ item_id, product_name, discountRate, basePrice, priceValue, salePrice, rating, ratingCount, ccidbadge, imageUrl, link, outofstock }) => {
  const [showModal, setShowModal] = useState(false);
  const initialOpacity = outofstock ? 0.5 : 1;
  const [opacityValue, setOpacityValue] = useState(initialOpacity);
  const [bgColor, setBgColor] = useState("");  // 초기 배경색은 빈 문자열로 설정



  const handleShowModal = () => {
    setShowModal(true);
    setOpacityValue(1);  // 모달창을 열 때 opacity를 1로 설정
    setBgColor("#f5f5f5");  // 모달창을 열 때 배경색을 파랑색으로 설정
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setOpacityValue(initialOpacity);  // 모달창을 닫을 때 초기 opacity 값으로 설정
    setBgColor("");  // 모달창을 열 때 배경색을 파랑색으로 설정
  };

  const handleProductClick = async () => {
    try {
      const shortenUrl = await getShortenUrl(link);
      window.open(shortenUrl, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.error('Failed to open link:', error);
      // 필요에 따라 사용자에게 오류 메시지를 표시할 수 있습니다.
    }
  };

  //별 모양을 만들기 위한 로직
  const renderStars = (rating) => {
    let stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <span className="star" key={i}>
          {i < rating ? "⭐" : "☆"}
        </span>
      );
    }
    return stars;
  };

  const handleSaveToLocalStorage = (event) => {

    event.stopPropagation();

    let existingPickedData = JSON.parse(localStorage.getItem('pickedItems') || '{}');
    const existingOriginals = Array.isArray(existingPickedData.original) ? existingPickedData.original : [];

    const newItem = {
      item_id, product_name, discountRate, basePrice, priceValue, salePrice,
      rating, ratingCount, ccidbadge, imageUrl, link, outofstock
    };

    if (Object.keys(existingPickedData).length === 0 || !Array.isArray(existingPickedData.original)) {
      existingPickedData = {
        original: [newItem],
        updated: []
      };
    } else {
      existingPickedData.original = [...existingOriginals, newItem];
    }

    localStorage.setItem('pickedItems', JSON.stringify(existingPickedData));
    console.log(localStorage.getItem('pickedItems'));
    // 반짝이는 효과를 주기 위한 로직
    const saveIcon = event.target;
    saveIcon.classList.add('blink');
    setTimeout(() => saveIcon.classList.remove('blink'), 1000);

    // Alarm 모달을 표시하는 로직 추가 
    // 개별 상품에 대해서 알람을 등록하는 로직인데 이러면 곤란해
    // MyPick 에서 알람을 등록하도록 해야 할 듯 싶다. 
    //handleAlarmClick();
  };

  const [showAlarm, setShowAlarm] = useState(false); // Alarm 모달을 위한 state

  const handleAlarmClick = (e) => {
    setShowAlarm(true);    // Alarm 모달 표시
  };
  const closeAlarmModal = () => {
    setShowAlarm(false);
  };




  return (
    <div
      className={`product-item ${outofstock ? 'out-of-stock' : ''}`}
      onClick={handleProductClick}
      style={{ backgroundColor: bgColor, opacity: opacityValue }}  // 배경색 동적 적용
    >

      <div className="product-image-wrapper">
        <span className="save-icon" onClick={handleSaveToLocalStorage}>🥟</span>  {/* "만두 찜기" 아이콘 추가 */}
        <img src={imageUrl} alt={product_name} style={{ width: '80%' }} />  {/* 이미지 크기를 80%로 줄임 */}
        {outofstock && <div className="out-of-stock-label">{outofstock}</div>}
      </div>
      <h2>{product_name}</h2>
      <div className="product-details">
        <div className="product-info">
          <div className="info-item discount">
            <span className="info-label discount">할인율:</span> {discountRate}↓
          </div>
          <div className="info-item out-of-stock">  {/* 새로 추가된 코드 */}
            <span className="info-label out-of-stock">{outofstock}</span>
          </div>
          <div className="info-item">
            <span className="info-label">기존 가격:</span> <del>{basePrice}원</del>
          </div>
          {priceValue &&
            <div className="info-item">
              <span className="info-label rainbow-text">할인 가격:</span> {priceValue}원
            </div>}
          {salePrice &&
            <div className="info-item">
              <span className="info-label">최종 가격:</span> <strong>{salePrice}</strong>
            </div>}
          {rating &&
            <div className="info-item">
              <span className="info-label">평점:</span> {renderStars(rating)}
            </div>}
          {ratingCount != 0 && ratingCount &&
            <div className="info-item">
              <span className="info-label">상품평수:</span> <span className="icon">👤</span>({ratingCount})
            </div>}
          <div className="badge">
            {ccidbadge}
          </div>
        </div>

      </div>
      <div
        className="product-history-link"
        onClick={(e) => {
          e.stopPropagation();
          handleShowModal();
        }}
        style={{ cursor: 'pointer' }}
      >
        📊 가격변동추이 📊
      </div>

      {showModal && <ProductPriceModal itemId={item_id} onClose={handleCloseModal} />}
      {showAlarm && <Alarm itemId={item_id} closeModal={closeAlarmModal} />}  {/* Alarm 모달 표시 조건 */}
    </div>
  );
};

export default Product;
