import React, { useEffect, useState, useRef } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceLine, Label } from 'recharts';

const ProductPriceDetail = ({ itemId }) => {
    const [priceChanges, setPriceChanges] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        fetch(`https://crawlmall.shop:8080/product_history/${itemId}`)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                const priceChangesWithParsedPrices = data.priceChanges.map(item => ({
                    date: new Date(item.date).getTime(),
                    price: parseInt(item.price.replace(/[^0-9]/g, ""), 10)
                }));

                setPriceChanges(priceChangesWithParsedPrices);
                console.log(priceChanges);
                setLoading(false);
            })
            .catch(error => console.error('Fetch error:', error));
    }, [itemId]);

    const graphContainerRef = useRef(null);

    const [chartDimensions, setChartDimensions] = useState({
        width: 500, // default values
        height: 300
    });

    useEffect(() => {
        if (graphContainerRef.current) {
            const { width } = graphContainerRef.current.getBoundingClientRect();
            setChartDimensions({
                width,
                height: 300 // 높이는 필요에 따라 조절 가능
            });
        }
    }, []);

    const isMobile = window.innerWidth <= 768;
    const chartWidth = isMobile ? window.innerWidth * 0.9 : chartDimensions.width;
    const chartHeight = isMobile ? 200 : chartDimensions.height; // 모바일일 때 높이를 200으로 설정
    

    // Conditional rendering for when data is still loading
    if (loading) {
        return <div>Loading...</div>;
    }

    // Conditional rendering for when priceChanges is empty
    if (priceChanges.length === 0) {
        return <div>가격 변동 정보가 없습니다.</div>;
    }

    const maxPriceData = priceChanges.reduce((max, p) => p.price > max.price ? p : max, priceChanges[0]);
    const minPriceData = priceChanges.reduce((min, p) => p.price < min.price ? p : min, priceChanges[0]);

    if (priceChanges.length === 0) {
        return (
            <div className="price-container">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                    가격 변동 정보가 없습니다.
                </div>
            </div>
        );
    }

    return (
        <div className="price-container">

            <div className="graph-container">
                <h3>가격 변동 그래프</h3>
                <LineChart
                    width={chartWidth}
                    height={chartHeight}
                    data={priceChanges}
                    margin={{ top: 20, right: 50, bottom: 20, left: 10 }} // 여기에 마진을 추가했습니다.
                >
                    <Line type="monotone" dataKey="price" stroke="#8884d8" />
                    <ReferenceLine x={maxPriceData.date} stroke="red">
                        <Label
                            value={`최고점: ${maxPriceData.price.toLocaleString()}원`}
                            position="center"
                            offset={10} // 필요에 따라 조절 가능
                            fill="red"
                        />
                    </ReferenceLine>

                    <ReferenceLine
                        x={minPriceData.date}
                        stroke="green"
                        label={{ value: `최저점: ${minPriceData.price.toLocaleString()}원`, position: 'insideBottomRight', offset: 10 }}
                    />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis
                        dataKey="date"
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        tickFormatter={(timestamp) => new Date(timestamp).toLocaleDateString()}
                    />
                    <YAxis domain={[0.8 * minPriceData.price, 1.2 * maxPriceData.price]} />
                    <Tooltip labelFormatter={(timestamp) => new Date(timestamp).toLocaleDateString()} />
                </LineChart>
            </div>

        </div>
    );
};

export default ProductPriceDetail;
